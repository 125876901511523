<p-toast></p-toast>
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7">
        <div class="card card-primary">
          <div class="card-header" style="background-color: #707dbf">
            <h3 class="card-title">Transacción</h3>
          </div>

          <form>
            <div class="card-body">
              <!-- Primera fila de campos -->
              <div class="row">
                <div class="col-6">
                  <label for="inputEmail3">Clientes</label>
                  <div>
     
                  
                    <p-autoComplete [suggestions]="filteredCountries" (onSelect)="onClientSelected($event)"
                    (completeMethod)="filterCountry($event)" field="name" [dropdown]="true">
                    <ng-template let-client pTemplate="item">
                      <div class="country-item">
                        <div>{{client.name}}</div>
                      </div>
                    </ng-template>
                  </p-autoComplete>

                  </div>
                </div>

                <div class="col-6 ml-auto">
                  <label for="exampleInputEmail1">Tipo de transacción</label>
                  <div>
                    <p-dropdown
                      requerid
                      placeholder="Seleccionar"
                      class="p-dropdown-globalBox"
                      appendTo="body"
                      optionLabel="name"
                      name="typeOfTransaction"
                      [(ngModel)]="selectedTypeOfTransaction"
                      [options]="typesOfTransaction"
                      [showClear]="true"
                      (onChange)="onTypeTransactionSelected($event)"
                    ></p-dropdown>
                  </div>
                </div>
              </div>

              <!-- Segunda fila de campos -->
              <div class="row segfila">
                <div class="col-6">
                  <label for="inputEmail3" class="col-form-label"
                    >Divisas</label
                  >
                  <div>
                    <p-dropdown
                      requerid
                      placeholder="Seleccionar"
                      class="p-dropdown-globalBox"
                      appendTo="body"
                      name="pair"
                      optionLabel="name"
                      [options]="par"
                      [(ngModel)]="selectedPair"
                      [showClear]="true"
                      (onChange)="onCurrencySelectedPar($event)"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-6 ml-auto">
                  <label for="quantity" class="col-form-label">Cantidad</label>
                  <div>
                    <input
                      tabindex="2"
                      type="text"
                      currencyMask
                      name="quantity"
                      [(ngModel)]="newTransaction.quantity"
                      quantity
                      pInputText
                      step="any"
                      inputmode="numeric"
                      maxlength="18"
                      id="quantity"
                      placeholder="Escriba una cantidad"
                      style="font-weight: 600"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <button
                type="submit"
                class="btn btn-primary"
                style="
                  background-color: #707dbf;
                  border-color: #707dbf;
                  float: right;
                "
                (click)="addNewTransactions(dtTransaction)"
              >
                Crear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>


    <div class="table">
      <p-table
        id="tableTransactions"
        #dtTransaction
        [value]="transactionsByTable"
        [paginator]="true"
        [rows]="4"
        [rowsPerPageOptions]="[1, 2, 3, 4, 5, 6]"
        selectionMode="single"
        autocomplete="off"
        class="global-table"
      >
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let column of fieldsTableTransactions">
              {{ column.title }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-transaction let-i="rowIndex">
          <tr>
            <td>{{ transaction.type_transaction }}</td>
            <td>{{ transaction.par }}</td>
            <td>{{ transaction.quantity }}</td>
            <td>{{ transaction.price }}</td>
            <td>{{ transaction.mountFinal }}</td>
            <td>{{ transaction.customer }}</td>
            <td>
              <div class="optionsButtons">
                <button pButton (click)="deleteTransaction(transaction)"  icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="4" class="text-right">Total</td>
            <td>{{ formatNumber(total) }}</td>
          </tr>
        </ng-template>
      </p-table>
      <div class="generatingBox">
        <button
          type="submit"
          class="btn btn-default float-right"
          style="background-color: #707dbf; color: white"
          (click)="generatingTransaction(dtTransaction)"
        >
          Generar
        </button>
      </div>
    </div>