// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from './auth-service.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  implements CanActivate {

  menuItems!: any[];
  role!:any;
  roles !: any;
  permissions: any;

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn$.pipe(
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['login']);
          return false;
        }
        
        const user = this.authService.getCurrentUser();
   
        if (user && user.role === 'Administrador') {
          return true;
        }
        
        const requiredPermissions = route.data['requiredPermissions'] as string[];

        if (!Array.isArray(requiredPermissions) || !requiredPermissions.every(p => typeof p === 'string')) {
          return false;
        }

        // Obtener los permisos del usuario
        const permissions = user?.permissions ?? {};
        const allPermissions = Object.values(permissions).flat();

        // Verificar si el usuario tiene todos los permisos requeridos
        const hasPermission = requiredPermissions.every(permission =>
          allPermissions.includes(permission)
        );

        if (hasPermission) {
          return true;
        } else {
          return true; 
        }
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
