import { AuthService } from 'src/app/auth/guards/auth-service.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { Transaction } from '../model/Transaction';
import { FIELDS_TABLE_TRANSACTION } from '../model/mocks/mock-fieldsTableTransaction';
import { Table } from 'primeng/table';
import { NumberFormatService } from '../services/numberFormat/number-format-service.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-history-transaction',
  templateUrl: './history-transaction.component.html',
  styleUrls: ['./history-transaction.component.css'],
  providers: [ConfirmationService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryTransactionComponent implements OnInit {

  transactions!: Transaction[];
  fieldsTableTransactions = FIELDS_TABLE_TRANSACTION;
  calendarIcon = 'pi pi-calendar';
  filteredData: any[] = [];
  isFilter = false;
  rangeDates: Date[] | undefined;
  value: string | undefined;
  records: any;
  dateTime = new Date();
  role!:any;
  userID!:any;  
  table: any;
  displayDelete!: boolean;
  user: any;
  canEdit: any;

  constructor(private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private numberFormatService: NumberFormatService,
    private authService:AuthService ) {
      this.dateTime.setDate(this.dateTime.getDate());
      this.role = this.authService.userRole;
      this.userID = parseInt(this.authService.userId);
      this.user = this.authService.getCurrentUser();
      if (this.user && this.user.permissions && this.user.permissions['transacciones']) {
        this.canEdit = this.user.permissions['transacciones'].includes('historial_editar') || this.role == 'Administrador';
      }
    }

  ngOnInit(): void {
    this.getTransaction();
    this.getTransactionDetails();
  }

  getTransactionDetails() {
    this.apiService.get(ApiService.apiURLBack + 'transaccionesDetalladas').subscribe({
      next: (response: any) => {
  
        this.table = response;
  
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getCurrencyName(fullName: string): string {
    const index = fullName.indexOf('/');
    return index !== -1 ? fullName.substring(0, index).trim() : fullName;
  }

  getCurrencyCode(fullName: string): string {
    const index = fullName.indexOf('/');
    return index !== -1 ? fullName.substring(index + 1).trim() : fullName;
  }

  applyDateFilter() {

    if (this.rangeDates && this.rangeDates.length === 2) {
      this.isFilter = true;
      const startDate = new Date(this.rangeDates[0]).getTime();
      const endDate = new Date(this.rangeDates[1]).getTime();  
  
        // Si las fechas son iguales, ajustar el filtrado para incluir todo el día
    if (startDate === endDate && this.rangeDates) {
      const newDate = this.formatDateToString(this.rangeDates[0]);

      this.filteredData = this.transactions.filter((item: any) => {
        const itemDate = item.date.split('T')[0]; 

        return itemDate === newDate;
      });
    }

    else {
      this.filteredData = this.transactions.filter((item: any) => {
        const itemDate = new Date(item.date.split('T')[0]).getTime();
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    } else {
      this.filteredData = this.transactions;
    }
  }

  formatDateOfBackToString(dateString: any): string {
    return dateString.slice(8, 10) + dateString.slice(4, 7) + '-' + dateString.slice(0, 4);
  }
  
  formatDateToString(dateString: Date): string {
  const date = new Date(dateString);
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
  }

  formatDateOfViuwToString(dateString: any): string {
    const date = new Date(dateString);
    const formattedDate = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
    return formattedDate;
  }

  clearFilter() {
    this.isFilter = false;
    this.rangeDates = undefined;
    this.filteredData = this.transactions;
  }

  getTransaction(){

    if(this.role == 'Cajero'){
      this.apiService.getById(ApiService.apiURLBack + 'transacciones',this.userID).subscribe({
        next: (response: any) => {
          this.transactions = response;
       // Verifica si this.transactions está vacío
       if (this.transactions.length === 0) {
        // Muestra un mensaje indicando que no hay transacciones
        this.messageService.add({
          severity: 'info',
          summary: 'No posee transacciones',
          sticky: true,  // Hace que el mensaje sea persistente
        });
      }
      this.cdr.detectChanges();
        },
        error: (error: { message: any }) => {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
            life: 2000,
          });
        },
      });
      return;
    }
    else {
      this.apiService.get(ApiService.apiURLBack + 'transaccion').subscribe({
        next: (response: any) => {
          this.transactions = response;
       
          this.cdr.detectChanges();
        },
        error: (error: { message: any }) => {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
            life: 2000,
          });
        },
      });
    }

    
  }

  deletetransaction (transactionAUX:any){
    this.displayDelete = true;
    this.cdr.detectChanges();
    this.confirmationService.confirm({
      message: 'Deseas eliminar esta transacción?',
      header: 'Confirmar operación',
      icon: 'pi pi-info-circle',
      accept: () => {
        const transactionToDelete = this.transactions.find(transaction => transaction.id === transactionAUX.id);
        if (transactionToDelete) {
          const index = this.transactions.indexOf(transactionToDelete);
          if (index !== -1) {
            this.transactions.splice(index, 1);

            this.cdr.detectChanges();
            this.apiService
              .delete(ApiService.apiURLBack + 'transaccion', transactionAUX.id)
              .subscribe(
                () => {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Confirmado',
                    detail: 'Registro eliminado',
                  });
                  this.getTransaction();
                },
                (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error al eliminar el registro',
                  });
                }
              );
          }
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Transacción no encontrada',
            detail: 'No se encontró la transacción a eliminar',
          });
        }
      },
    });
  }

  formatNumber(value: number): string {
    return this.numberFormatService.formatNumber(value);
  }

  clear(table: Table) {
    table.clear();
}



}