import { MatListModule } from '@angular/material/list';
import { TransactionByTableRequest } from './../model/TransactionRequestByTable';
import { Transaction } from './../model/Transaction';
import { FIELDS_TABLE_TRANSACTIONS } from './../model/mocks/mock-fieldsTableFinalTransactions';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from '../services/api-service/apiService/api.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AuthService } from '../auth/guards/auth-service.service';
import { NgControl } from '@angular/forms';
import { TransactionRequest } from '../model/TransactionRequest';
import { Transactions } from '../model/FinalTransactionRequest';
import { FIELDS_TABLE_TRANSACTION } from '../model/mocks/mock-fieldsTableTransaction';
import { audit } from 'rxjs';
import { NumberFormatService } from '../services/numberFormat/number-format-service.service';

import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';

export const customCurrencyMaskConfig2: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 3,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
  providers: [ConfirmationService, MessageService,
    { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig2 }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionComponent implements OnInit {
  transactions!: TransactionRequest[];
  clients!: any;
  typesOfTransaction!: any;
  currencies!: any;
  selectedIdCurrencyPar: any;
  selectedIdCurrencyDestiny: any;
  selectedIdClient: number | undefined;
  selectedIdTypeTransaction: any;
  newObject!: any;
  newTransaction!: Transaction;
  pointByUserID: any;
  userID: any;
  displayTotal!: boolean;
  total!:any;
  price!: any;
  mountFinal!:any;
  par!: any;
  cotizaciones!: any;
  formattedPrice: any;
  selectedTypeOfTransaction: any;
  selectedPair: any;
  transactionRequest!: TransactionRequest;
  parsedNumber!: number;
  transactionsByTable!: any[];
  TransactionByTableRequest!: TransactionByTableRequest;
  boxInit!: Transactions;
  fieldsTableTransactions = FIELDS_TABLE_TRANSACTIONS;
  hasTriedToAdd!: boolean;
  nameClient: any;
  nameSelectedClient:any;
  filteredCountries!: any[];
  selectedClient: any;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private authService: AuthService,
    private numberFormatService: NumberFormatService
  ) {
    this.userID = parseInt(this.authService.userId);
  }

  ngOnInit(): void {
    this.getClients();
    this.getTypeOfTransaction();
    this.getCurrencies();
    this.getPoints();
    this.getPar();
    this.getCotizaciones();
    this.newTransaction = new Transaction();
    this.transactionRequest = new TransactionRequest();
    this.transactions = new Array<TransactionRequest>();
    this.TransactionByTableRequest = new TransactionByTableRequest();
    this.transactionsByTable = [];
    this.newTransaction.quantity = '';
    this.selectedIdClient = 1;
    this.price = '0';
    this.mountFinal = '0';
    this.total = 0;

  }

  getClients() {
    this.apiService.get(ApiService.apiURLBack + 'cliente').subscribe({
      next: (response: any) => {
        this.clients = response;

        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPoints() {
    this.apiService.get(ApiService.apiURLBack + 'punto').subscribe({
      next: (response: any) => {
        this.pointByUserID = response.find(
          (item: { user: { id: any } }) => item.user.id === this.userID
        ).id;
        if (!this.pointByUserID) {
          this.pointByUserID = 1;
        }
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getPar() {
    this.apiService.get(ApiService.apiURLBack + 'par').subscribe({
      next: (response: any) => {
        this.par = response;
        // Forzar la actualización de la tabla
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getCotizaciones() {
    this.apiService.get(ApiService.apiURLBack + 'ratio').subscribe({
      next: (response: any) => {
        this.cotizaciones = response;

        // Forzar la actualización de la tabla
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getTypeOfTransaction() {
    this.apiService.get(ApiService.apiURLBack + 'tipoTransaccion').subscribe({
      next: (response: any) => {
        this.typesOfTransaction = response;

        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  getCurrencies() {
    this.apiService.get(ApiService.apiURLBack + 'divisa').subscribe({
      next: (response: any) => {
        this.currencies = response;
        this.cdr.detectChanges();
      },
      error: (error: { message: any }) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message,
          life: 2000,
        });
      },
    });
  }

  addNewTransactions(dtTransactions: any) {
    this.hasTriedToAdd = true;

    this.newTransaction.user_id = this.userID;
    this.newTransaction.customer_id = this.selectedIdClient;
    this.newTransaction.type_transaction_id = this.selectedIdTypeTransaction;
    this.newTransaction.pair_id = this.selectedIdCurrencyPar;
    this.newTransaction.point_of_sell_id = this.pointByUserID;

    if (this.TransactionByTableRequest.customer == undefined) {
      this.TransactionByTableRequest.customer = this.clients[0].name;
    }
 
    this.TransactionByTableRequest.quantity = this.newTransaction.quantity;
    if (
      this.newTransaction.pair_id != undefined &&
      this.newTransaction.type_transaction_id != undefined &&
      this.newTransaction.quantity != "" &&
      this.newTransaction.customer_id != undefined &&
      this.newTransaction.point_of_sell_id != undefined
    ) {
  
      if(this.transactionsByTable.length !=0) {
      if (
        this.nameClient != undefined &&
        this.nameSelectedClient != this.nameClient
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Por favor seleccionar un mismo cliente para la transacciòn',
          life: 3000,
        });
        return;
      }
    }
  
      this.showTotal();
    }
    
    else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Debe completar todos los campos',
        life: 3000,
      });
      return;
    }

    
    dtTransactions.reset();
    dtTransactions.totalRecords = this.transactionsByTable.length;
    dtTransactions._first = 0;
    this.hasTriedToAdd = false;
  }

  deleteTransaction(transaction: any) {
    const indexTable = this.transactionsByTable.indexOf(transaction);
    const numParsed = parseFloat(
      transaction.mountFinal.replace(/\./g, '').replace(',', '.')
    );
    this.total = this.total - numParsed;
    if (indexTable !== -1) {
      this.transactionsByTable.splice(indexTable, 1);
    }

    const idPar = this.par.find( (aux: { name: any }) => aux.name === transaction.par).id;
    const typeOfTran = this.typesOfTransaction.find((aux: { name: any }) => aux.name === transaction.type_transaction).id;

    const auxTransactionDelete = {
      par_id: idPar,
      type_of_transaction: typeOfTran,
    };
    const indexTransactions = this.transactions.findIndex(
      (trans: any) =>
        trans.pair_id === auxTransactionDelete.par_id &&
        trans.type_transaction_id === auxTransactionDelete.type_of_transaction
    );

    if (indexTransactions !== -1) {
      this.transactions.splice(indexTransactions, 1);
    }
  }

  filterCountry(event:any) {
    let filtered: any[] = [];
    let query = event.query;
 
    for (let i = 0; i < this.clients.length; i++) {
      let client = this.clients[i];
      if (client.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(client);
      }
    }
    this.filteredCountries = filtered;
    console.log(this.selectedClient);
  }

  onCurrencySelectedPar(event: any) {
    if (!event.value) {
      this.selectedIdCurrencyPar = undefined;
    } else {
      this.TransactionByTableRequest.par = event.value.name;
      this.selectedIdCurrencyPar = event.value.id;
      this.selectedPair = this.par.find(
        (p: { id: any }) => p.id == event.value.id
      );
    }
  }

  onClientSelected(event: any) {
    if (!event.name) {
      this.selectedIdClient = undefined;
    } else {
      this.TransactionByTableRequest.customer = event.name;
      this.nameSelectedClient = event.name;
      this.selectedIdClient = event.id;
    }
  }

  onTypeTransactionSelected(event: any) {
    if (!event.value) {
      this.selectedIdTypeTransaction = undefined;
    } else {
      this.TransactionByTableRequest.type_transaction = event.value.name;
      this.selectedIdTypeTransaction = event.value.id;
      this.selectedTypeOfTransaction = this.typesOfTransaction.find(
        (p: { id: any }) => p.id == event.value.id
      );
    }
  }

  showTotal() {

    this.transactionRequest.customer_id = this.selectedIdClient;
    this.transactionRequest.pair_id = this.newTransaction.pair_id;
    this.transactionRequest.point_of_sell_id =
      this.newTransaction.point_of_sell_id;
    this.transactionRequest.type_transaction_id = this.selectedIdTypeTransaction;
    this.transactionRequest.user_id = this.newTransaction.user_id;
    const numberAsString = this.newTransaction.quantity ?? '0';
    this.parsedNumber = parseFloat(
      numberAsString
    );
    this.transactionRequest.quantity = this.parsedNumber;

    this.nameClient = this.nameSelectedClient;

    const existingTransaction1 = this.transactions.find(
      (trans: any) =>
        trans.pair_id === this.transactionRequest.pair_id &&
        trans.type_transaction_id === this.selectedIdTypeTransaction
    );

    const existingTransaction2 = this.transactions.find(
      (trans: any) =>
        trans.pair_id === this.transactionRequest.pair_id &&
        trans.type_transaction_id === this.selectedIdTypeTransaction
    );

    if (existingTransaction1 !=undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Ya existe una transacción de compra o venta para esta divisa',
        life: 3000,
      });
      return;
    }

    if (existingTransaction2!=undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Ya existe una transacción de compra o venta para esta divisa',
        life: 3000,
      });
      return;
    }

    else {

      let existRatio = this.cotizaciones.find(
        (aux: { pair_id: { id: number | undefined } }) =>
          aux.pair_id.id == this.newTransaction.pair_id
      );
  
      if (existRatio != undefined) {
        this.apiService
          .getById(
            ApiService.apiURLBack + 'cotizacion',
            this.selectedIdCurrencyPar
          )
          .subscribe({
            next: (response: any) => {
              this.cotizaciones = response;
              if (
                this.selectedIdTypeTransaction == 1 &&
                this.newTransaction.quantity
              ) {
                const numberAsString = this.newTransaction.quantity ?? '0';
                this.parsedNumber = parseFloat(
                  numberAsString
                );
                const responseMountFinal = response[0].mount_buy * this.parsedNumber;
                this.price = response[0].mount_buy;
                this.mountFinal = this.formatNumber(responseMountFinal);
                this.total = this.total + responseMountFinal;
              } else if (
                this.selectedIdTypeTransaction == 2 &&
                this.newTransaction.quantity
              ) {
  
                const numberAsString = this.newTransaction.quantity ?? '0';
                this.parsedNumber = parseFloat(
                  numberAsString
                );
                const responseMountFinal = response[0].mount_sell * this.parsedNumber;
                this.price = response[0].mount_sell;
                this.mountFinal = this.formatNumber(responseMountFinal);
                this.total = this.total + responseMountFinal;
               
              }
  
        this.TransactionByTableRequest.mountFinal = this.mountFinal;
        this.TransactionByTableRequest.price = this.price;
        this.transactionsByTable.push(this.TransactionByTableRequest);
  
        const newTransactionCopy: TransactionRequest = {
          ...this.transactionRequest,
        };
        this.transactions.push(newTransactionCopy);
        this.clearCampos2();
  
        this.cdr.detectChanges();
  
              this.getCotizaciones();
              this.cdr.detectChanges();
            },
            error: (error: { message: any }) => {
              this.messageService.add({
                severity: 'error',
                summary: error.message,
                life: 2000,
              });
            },
          });
  
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Debe generar una cotizaciòn',
          life: 3000,
        });
      }
    }

    
  }

  clearCampos() {
    this.newTransaction = new Transaction();
    this.TransactionByTableRequest = new TransactionByTableRequest();
    this.transactionRequest = new TransactionRequest();
    this.newTransaction.quantity = undefined;
    this.selectedIdClient = 1;
    this.selectedIdTypeTransaction = undefined;
    this.selectedTypeOfTransaction = undefined;
    this.selectedPair = undefined;
    this.selectedIdCurrencyPar = undefined;
  }

  clearCampos2() {
    this.newTransaction = new Transaction();
    this.TransactionByTableRequest = new TransactionByTableRequest();
    this.transactionRequest = new TransactionRequest();
    this.newTransaction.quantity = undefined;
    this.selectedIdTypeTransaction = undefined;
    this.selectedTypeOfTransaction = undefined;
    this.selectedPair = undefined;
    this.selectedIdCurrencyPar = undefined;
  }

  generatingTransaction(dtTransactions: any) {
    const auxTransaction: Transactions = {
      transactions: this.transactions,
    };

    this.apiService
      .post(ApiService.apiURLBack + 'transaccion', auxTransaction)
      .subscribe({
        next: (response: any) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Transaccion agregada exitosamente',
            life: 2000,
          });
          this.total = 0;
          this.transactions = new Array<TransactionRequest>();
          this.transactionsByTable = [];
          dtTransactions.reset();
          dtTransactions.totalRecords = this.transactionsByTable.length;
          dtTransactions._first = 0;
          this.clearCampos();
          this.getCotizaciones();
          this.getPar();

          this.cdr.detectChanges();
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
            sticky: true, // Hace que el mensaje sea persistente
          });
        },
      });
  }

  formatNumber(value: number): string {
    return this.numberFormatService.formatNumber(value);
  }
}