import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogginComponent } from './loggin/loggin.component';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {PasswordModule} from 'primeng/password';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import {MenuModule} from 'primeng/menu';
import {TabMenuModule} from 'primeng/tabmenu';
import {StepsModule} from 'primeng/steps';
import {CardModule} from 'primeng/card';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {SpeedDialModule} from 'primeng/speeddial';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {DropdownModule} from 'primeng/dropdown';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { HttpClientModule } from '@angular/common/http';
import { MenubarModule } from 'primeng/menubar';
import { SettingsComponent } from './settings/settings.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ChartModule } from 'primeng/chart';
import { SidevarComponent } from './shared/sidevar/sidevar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { BreadcrumsComponent } from './shared/breadcrums/breadcrums.component';
import { HeaderComponent } from './shared/header/header.component';
import { UsersComponent } from './users/users.component';
import { RolesComponent } from './roles/roles.component';
import { CurrenciesComponent } from './currencies/currencies.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages/pages.component';
import { PointOfChangeComponent } from './point-of-change/point-of-change.component';
import { ClientsComponent } from './clients/clients.component';
import { TransactionComponent } from './transaction/transaction.component';
import { HistoryTransactionComponent } from './history-transaction/history-transaction.component';
import { AuthService } from './auth/guards/auth-service.service';
import { authTokeninterceptorProvider } from './auth/guards/interceptors/auth-token.interceptor';
import { BoxInitComponent } from './box-init/box-init.component';
import { BoxStatusComponent } from './box-status/box-status.component';
import { AmountReportComponent } from './amount-report/amount-report.component';
import { TransactionPendientComponent } from './transaction-pendient/transaction-pendient.component';
import { ShowCotizacionComponent } from './show-cotizacion/show-cotizacion.component';
import { ShowTransactionsPendientComponent } from './show-transactions-pendient/show-transactions-pendient.component';
import {MultiSelectModule} from 'primeng/multiselect';
import { ParComponent } from './par/par.component';
import { BoxMovimientosComponent } from './box-movimientos/box-movimientos.component';
import { CalendarModule } from 'primeng/calendar';
import { NumberFormatDirective } from './transaction/numberFormatDirective';
import { PermissionComponent } from './permission/permission.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoaderComponent } from './services/loader/loader.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import {PaginatorModule} from 'primeng/paginator';


export const customCurrencyMaskConfig: any = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 4,
  prefix: "$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

export const customCurrencyMaskConfig2: any = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};


@NgModule({
  declarations: [
    AppComponent,
    LogginComponent,
    SettingsComponent,
    UsersComponent,
    RolesComponent,
    CurrenciesComponent,
    SidevarComponent,
    FooterComponent,
    BreadcrumsComponent,
    HeaderComponent,
    DashboardComponent,
    PagesComponent,
    PointOfChangeComponent,
    ClientsComponent,
    TransactionComponent,
    HistoryTransactionComponent,
    BoxInitComponent,
    BoxStatusComponent,
    AmountReportComponent,
    TransactionPendientComponent,
    ShowCotizacionComponent,
    ShowTransactionsPendientComponent,
    ParComponent,
    BoxMovimientosComponent,
    NumberFormatDirective,
    PermissionComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InputTextModule,
    ButtonModule,
    PasswordModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    MenuModule,
    MenubarModule,
    TabMenuModule,
    StepsModule,
    CardModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    ConfirmDialogModule,
    SpeedDialModule,
    DialogModule,
    DynamicDialogModule,
    AvatarGroupModule,
    AvatarModule,
    DropdownModule,
    AutoCompleteModule,
    HttpClientModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    PanelMenuModule,
    SplitButtonModule,
    ChartModule,
    MessageModule,
    ConfirmDialogModule,
    MultiSelectModule,
    CalendarModule,
    InputSwitchModule,
    PaginatorModule,
    ProgressSpinnerModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  providers: [authTokeninterceptorProvider,MessageService,AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
