import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { UserWithToken } from 'src/app/model/user.interface';
import { ConfigService } from 'src/app/config.service';
import { catchError } from 'rxjs/operators';

const USER_LOCAL_STORAGE_KEY = 'user_info'; 

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  static readonly apiURLBack =  'https://api.easierchange.site/api/';
  private user: UserWithToken | null = null;

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  private getAuthHeaders(): { headers: HttpHeaders } {
    const token = this.user?.token || ''; 
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return { headers };
  }


  getUser(): UserWithToken | null {
    return this.user;
  }


  setUser(user: UserWithToken | null) {
    this.user = user;
  }

  private loadUserFromLocalStorage(): void {
    const userFromLocal = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
    if (userFromLocal) {
      const userInfo: UserWithToken = JSON.parse(userFromLocal);
      this.setUser(userInfo); 
    }
  }

  get(url: string): Observable<any> {
     const options = this.getAuthHeaders();
    return this.httpClient.get<any>(url,options).pipe(
      catchError(this.handleError)
    );
  }

  getByIdRoles(url: string,id:BigInt): Observable<any> {
    const options = this.getAuthHeaders();
   return this.httpClient.get<any>(url+ `/${id}`).pipe(
     catchError(this.handleError)
   );
 }

  getById(url: string, id: BigInt): any {
    return this.httpClient.get(url + `/${id}`).pipe(catchError(this.handleError));
  }

  getDashboard(pairId?: number, startDate?: string, finishDate?: string, users?: number[]): Observable<any> {
    let params = new HttpParams();
  
    if (pairId !== undefined) {
      params = params.set('pairId', pairId.toString());
    }
    if (startDate) {
      params = params.set('startDate', startDate);
    }
    if (finishDate) {
      params = params.set('finishDate', finishDate);
    }
    if (users && users.length > 0) {
      params = params.set('users', users.join(','));
    }
  
    const url = ApiService.apiURLBack + 'dashboard';
    const fullUrl = `${url}?${params.toString()}`;
  
    return this.httpClient.get(fullUrl).pipe(catchError(this.handleError));
  }

  getTransactionByPage(page: number, rows: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('rows', rows.toString());
    const url = ApiService.apiURLBack + 'transaccion';
    return this.httpClient.get(url,{params}).pipe(catchError(this.handleError));
  }

  getTransaction(pairId?: number, startDate?: string): Observable<any> {
    let params = new HttpParams();
  
    if (pairId !== undefined) {
      params = params.set('pairId', pairId.toString());
    }
    if (startDate) {
      params = params.set('startDate', startDate);
    }
  
    const url = ApiService.apiURLBack + 'transaction';
    const fullUrl = `${url}?${params.toString()}`;
  
    return this.httpClient.get(fullUrl).pipe(catchError(this.handleError));
  }


  getDashboard2(startDate?: string, finishDate?: string, users?: number[]): Observable<any> {
    let params = new HttpParams();
  
    if (startDate) {
      params = params.set('startDate', startDate);
    }
    if (finishDate) {
      params = params.set('finishDate', finishDate);
    }
    if (users && users.length > 0) {
      params = params.set('users', users.join(','));
    }
  
    const url = ApiService.apiURLBack + 'dashboard';
    const fullUrl = `${url}?${params.toString()}`;
  
    return this.httpClient.get(fullUrl).pipe(catchError(this.handleError));
  }

  getDashboard3( startDate: string, finishDate: string): Observable<any> {
    const params = new HttpParams()
      .set('startDate', startDate)
      .set('finishDate', finishDate);
    const url = ApiService.apiURLBack + 'dashboard';

    
    const fullUrl = `${url}?${params.toString()}`;

    return this.httpClient.get(fullUrl).pipe(catchError(this.handleError));
  }

  post(url: string, body: any){
    const options = this.getAuthHeaders();
    return this.httpClient.post(url, body)
    .pipe(
      catchError(this.handleError));
  }


  postBox(url: string, id:any, body: any) {
    const options = this.getAuthHeaders();
    return this.httpClient.post(url+ `/${id}/iniciar`, body, options)
    .pipe(
      catchError(this.handleError));
  }

  postTransactionPendient(url: string, id:any, body:any) {
    const options = this.getAuthHeaders();
    return this.httpClient.post(url+ `/${id}`,body, options)
    .pipe(
      catchError(this.handleError));
  }

  put(url: string, id:any,body: any){
    const options = this.getAuthHeaders();
    return this.httpClient.put(url+`/${id}`, body)
    .pipe(
      catchError(this.handleError));
  }

  delete(url: string, id: any){
    const options = this.getAuthHeaders();
    return this.httpClient.delete(url +`/${id}`)
    .pipe(
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === HttpStatusCode.BadRequest) {
      if (error.error && error.error.Error) {
        return throwError(() => new Error(error.error.Error));
      } else {
        return throwError(() => new Error("Hay un problema con la solicitud"));
      }
    } else if (error.status === HttpStatusCode.NotFound) {
      return throwError(() => new Error("No se encuentra el contenido solicitado"));
    } else if (error.status === HttpStatusCode.NoContent) {
      return throwError(() => new Error("No hay contenido para borrar"));
    }
    return throwError(() => new Error("Ups, algo salió mal"));
  }
}
